/** @format */

import gql from "graphql-tag";

export const ListFrames = gql`
	query ListFramesQuery($CourseID: ID) {
		listCourseFrames(filter: { courseID: { eq: $CourseID } }) {
			items {
				id
				order
				courseID
				status
				createdAt
				updatedAt
				frames {
					items {
						name
						status
						type
						id
						icon
					}
				}
			}
		}
	}
`;

export const GetCourse = gql`
	query GetCourseQuery {
		getCourse(id: $id) {
			course_name
			frames {
				items {
					createdAt
					icon
					id
					name
					order
					type
					status
				}
			}
		}
	}
`;

export const NewFrame = gql`
	mutation NewFrameMutation(
		$CourseFrameId: ID!
		$CourseID: ID!
		$FrameID: ID!
		$order: Int = 0
		$FrameType: FRAMETYPE
		$FrameName: String!
	) {
		createCourseFrame(
			input: {
				courseID: $CourseID
				id: $CourseFrameId
				order: $order
				status: ACTIVE
			}
		) {
			id
			courseID
		}
		createFrame(
			input: {
				CourseFrameId: $CourseFrameId
				courseID: $CourseID
				id: $FrameID
				name: $FrameName
				status: ACTIVE
				type: $FrameType
			}
		) {
			id
			courseID
		}
	}
`;

export const onFrameByCourseID = gql`
	subscription onFrameByCourseID($courseID: ID!) {
		onFrameByCourseID(courseID: $courseID) {
			id
		}
	}
`;
