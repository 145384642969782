/** @format */

import {
	IonButton,
	IonContent,
	IonHeader,
	IonIcon,
	IonItem,
	IonLabel,
	IonList,
	IonListHeader,
	IonMenu,
	IonMenuToggle,
	IonNote,
	IonTitle,
	IonToolbar,
} from "@ionic/react";

import React from "react";
import { useLocation } from "react-router-dom";
import {
	playCircleOutline,
	playCircleSharp,
	calendarOutline,
	calendarSharp,
	bookOutline,
	bookSharp,
	peopleCircleOutline,
	peopleCircleSharp,
	homeOutline,
	homeSharp,
	warningOutline,
	warningSharp,
} from "ionicons/icons";
import "./Menu.css";
import { AmplifySignOut } from "@aws-amplify/ui-react";
import { Button } from "../removed/Button";

interface AppPage {
	url: string;
	iosIcon: string;
	mdIcon: string;
	title: string;
}

const appPages: AppPage[] = [
	{
		title: "Home",
		url: "/app",
		iosIcon: homeOutline,
		mdIcon: homeSharp,
	},
	{
		title: "Courses",
		url: "/Courses",
		iosIcon: bookOutline,
		mdIcon: bookSharp,
	},
	{
		title: "Students",
		url: "/Students",
		iosIcon: peopleCircleOutline,
		mdIcon: peopleCircleSharp,
	},
	{
		title: "Registrations",
		url: "/Registrations",
		iosIcon: calendarOutline,
		mdIcon: calendarSharp,
	},
	{
		title: "Player",
		url: "/Player",
		iosIcon: playCircleOutline,
		mdIcon: playCircleSharp,
	},
];

const Menu: React.FC = () => {
	const location = useLocation();

	return (
		<IonMenu contentId='main' type='overlay' side='start'>
			<IonHeader>
				<IonToolbar color='tertiary'>
					<IonTitle>Viarspace</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<IonList id='inbox-list' style={{ background: "DarkSlateBlue" }}>
					<IonListHeader>Viarspace</IonListHeader>
					<IonNote>User First Name And Last Name</IonNote>

					{appPages.map((appPage, index) => {
						return (
							<IonMenuToggle key={index} autoHide={false}>
								<IonItem
									className={
										location.pathname === appPage.url ? "selected" : ""
									}
									routerLink={appPage.url}
									routerDirection='none'
									lines='none'
									detail={false}>
									<IonIcon
										slot='start'
										ios={appPage.iosIcon}
										md={appPage.mdIcon}
									/>
									<IonLabel>{appPage.title}</IonLabel>
								</IonItem>
							</IonMenuToggle>
						);
					})}
				</IonList>
				<IonButton expand='block' fill='outline' routerLink='/login'>
					Sign In
				</IonButton>
				<AmplifySignOut button-text='Custom Text'></AmplifySignOut>
			</IonContent>
		</IonMenu>
	);
};

export default Menu;
