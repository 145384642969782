/** @format */

import {
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardTitle,
	IonContent,
	IonFabButton,
	IonIcon,
	IonPage,
	IonProgressBar,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { add, close, cloudUpload } from "ionicons/icons";
import { v4 as uuidv4 } from "uuid";
// Amplify
import Amplify, { Storage } from "aws-amplify";
import awsconfig from "../aws-exports";
Amplify.configure(awsconfig);
const FrameSettings = (props: any) => {
	const { frame } = props;
	const [progress, setProgress] = useState(0);
	const uploadRef = React.useRef<HTMLInputElement>(null);
	useEffect(() => {
		//console.log("Frame", frame);
	}, [frame]);
	const setPicture = async (e: any) => {
		const file = e[0];
		const fileExtension = file.name.split(".")[1];
		const fileId = uuidv4();
		const fileName = fileId + "." + fileExtension;

		Storage.put(fileName, file, {
			progressCallback(prog: any) {
				setProgress(Number(prog.loaded) / Number(prog.total));
			},
			contentType: file.contentType,
		})
			.then((result) => props.onUpload(result))
			.catch((err: any) => console.log(err));
	};
	return (
		<>
			{progress < 1 && progress != 0 ? (
				<IonProgressBar value={progress}></IonProgressBar>
			) : (
				<div
					style={{
						width: "100%",
					}}>
					{props.file ? (
						<div style={{ display: "flex", justifyContent: "center" }}>
							<IonFabButton onClick={(e) => props.onUpload("")}>
								<IonIcon icon={close}></IonIcon>
							</IonFabButton>
						</div>
					) : (
						<div style={{ display: "flex", justifyContent: "center" }}>
							<IonFabButton onClick={(e) => uploadRef.current?.click()}>
								<IonIcon icon={cloudUpload}></IonIcon>
							</IonFabButton>
						</div>
					)}
					<input
						capture='user'
						accept='image/*'
						type='file'
						ref={uploadRef}
						style={{ opacity: 0 }}
						onChange={(e) => setPicture(e.target.files)}></input>
				</div>
			)}
		</>
	);
};
export default FrameSettings;
