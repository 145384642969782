/** @format */
import Amplify, { API, graphqlOperation, Hub, Auth } from "aws-amplify";
import awsconfig from "../aws-exports";
import gql from "graphql-tag";
import { from, fromEvent, pipe } from "rxjs";
import { pluck, first } from "rxjs/operators";
import * as Bowser from "bowser";
import { I18n } from "aws-amplify";
import { Translations } from "@aws-amplify/ui-components";

const publicIp = require("public-ip");
Amplify.configure(awsconfig);
export const appMiddle = (store) => (next) => (action) => {
	//console.log("action", action);
	switch (action.type) {
		case "app/initApp":
			//console.log(Auth, Hub);
			const hub = (ev) => {
				//console.log("Hub Event", ev);
				let payload = JSON.parse(
					JSON.stringify(ev, (n, v) => {
						return typeof n !== "object" ? v : null;
					})
				);
				store.dispatch({
					type: "app/setHub",
					payload: payload,
				});
			};
			Hub.listen(/.*/, hub);
			// AWS Config
			store.dispatch({ type: "app/setAwsconfig", payload: awsconfig });
			// Location
			const windowLocation = JSON.parse(
				JSON.stringify(window.location, (n, v) => {
					return typeof n !== "object" ? v : null;
				})
			);
			store.dispatch({
				type: "app/setLocation",
				payload: windowLocation,
			});
			//Screen
			store.dispatch({
				type: "app/setScreen",
				payload: {
					width: window.screen.width,
					height: window.screen.height,
				},
			});
			// Browser
			/*
			store.dispatch({
				type: "app/setBrowser",
				payload: Bowser.parse(window.navigator.userAgent),
			});
			(async () => {
				store.dispatch({
					type: "app/setIp",
					payload: await publicIp.v4(),
				});
			})();*/
			I18n.putVocabulariesForLanguage("en-US", {
				[Translations.SIGN_IN_HEADER_TEXT]: "Viarspace",
				[Translations.SIGN_IN_TEXT]: "Sign In",
				[Translations.SIGN_IN_ACTION]: "Sign In",
			});
			break;
		case "app/setLocation":
			// console.log("payload", action.payload.hostname);
			from(
				API.graphql({
					query: gqlGetApp,
					variables: { id: action.payload.host },
				})
			)
				.pipe(pluck("data", "getApp"))
				.subscribe((res) => {
					store.dispatch({
						type: "app/setGetApp",
						payload: res,
					});
				});
			break;
		case "app/setHub":
			switch (action.payload.channel) {
				case "UI Auth":
					switch (action.payload.payload.message) {
						case "signedin":
							store.dispatch({
								type: "user/getUser",
								payload: {
									id: action.payload.payload.data.attributes.sub,
								},
							});
							store.dispatch({
								type: "user/subscribeToUser",
								payload: {
									id: action.payload.payload.data.attributes.sub,
								},
							});
							/*
							from(
								API.graphql({
									query: gqlGetUser,
									variables: { id: action.payload.payload.data.attributes.sub },
								})
							)
								.pipe(pluck("data"))
								.subscribe((res) => {
									console.log(res);
								});
*/
							break;
						default:
							break;
					}
					break;
				default:
			}
			break;

		default:
	}

	return next(action);
};

const gqlGetApp = gql`
	query getApp($id: ID!) {
		getApp(id: $id) {
			id
			createdAt
			model
			name
			status
			updatedAt
		}
	}
`;

const gqlGetUser = gql`
	query getUserQuery($id: ID!) {
		getUser(id: $id) {
			id
			UserAccountId
			userAccount {
				account {
					appID
					id
					createdAt
					name
					updatedAt
					app {
						createdAt
						id
						name
						updatedAt
					}
				}
			}
			sub
			email
			email_verified
			first_name
			last_name
			phone_number
			phone_number_verified
			createdAt
			updatedAt
		}
	}
`;
