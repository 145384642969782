/** @format */

import {
	IonButton,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardTitle,
	IonContent,
	IonFabButton,
	IonIcon,
	IonPage,
	IonSegment,
	IonSegmentButton,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import FileUploader from "../components/FileUploader";

import { add, cloudUpload, play } from "ionicons/icons";

const FrameVideo: React.FC = () => {
	const [showModal, setShowModal] = useState(false);
	const [newFrame, setNewFrame] = useState();
	useEffect(() => {
		setShowModal(false);
	}, [newFrame]);
	return (
		<>
			<IonButton expand='block' fill='outline' size='small'>
				<IonIcon icon={play}></IonIcon>
			</IonButton>
			<IonCard>
				<IonCardHeader>
					<IonCardTitle>Video</IonCardTitle>
				</IonCardHeader>
				<IonCardContent>
					<FileUploader></FileUploader>
				</IonCardContent>
			</IonCard>
		</>
	);
};
export default FrameVideo;
