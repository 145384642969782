/** @format */
import Amplify, { API, graphqlOperation, Hub, Auth } from "aws-amplify";
import awsconfig from "../aws-exports";
import gql from "graphql-tag";
import { from, fromEvent, pipe } from "rxjs";
import { pluck, first } from "rxjs/operators";
import * as Bowser from "bowser";
import { I18n } from "aws-amplify";
import { Translations } from "@aws-amplify/ui-components";
import {
	ListFrames,
	NewFrame,
	onFrameChangeByCourseID,
	onFrameByCourseID,
} from "./courseGql";
import { v4 as uuidv4 } from "uuid";
const publicIp = require("public-ip");
Amplify.configure(awsconfig);
export const courseMiddle = (store) => (next) => (action) => {
	//console.log("action", action);
	switch (action.type) {
		case "course/initCourse":
			store.dispatch({
				type: "course/setCourseId",
				payload: action.payload,
			});
			store.dispatch({
				type: "course/subscribeToFrames",
				payload: action.payload,
			});
			break;
		case "course/setFrameNew":
			const variables = {
				...action.payload,
				CourseFrameId: uuidv4(),
				CourseID: store.getState().course.id,
				FrameID: uuidv4(),
			};

			from(
				API.graphql({
					query: NewFrame,
					variables: variables,
				})
			).subscribe();
			break;
		case "course/subscribeToFrames":
			from(
				API.graphql({
					query: onFrameByCourseID,
					variables: { courseID: store.getState().course.id },
				})
			).subscribe({
				next: (data) => {
					store.dispatch({
						type: "course/getFrames",
						payload: store.getState().course.id,
					});
				},
				error: (error) => {
					console.log("Error subscribing to courses", error);
				},
			});
			break;
		case "course/setCourseId":
			store.dispatch({
				type: "course/getFrames",
				payload: action.payload,
			});
			break;
		case "course/getFrames":
			from(
				API.graphql({
					query: ListFrames,
					variables: {
						CourseID: action.payload,
					},
				})
			)
				.pipe(pluck("data", "listCourseFrames", "items"))
				.subscribe({
					next: (frames) => {
						store.dispatch({ type: "course/setFrames", payload: frames });
					},
					error: (error) => {
						console.log("Error getting frames", error);
					},
				});
			break;
		default:
	}

	return next(action);
};
