/** @format */

import { IonCard, IonCardContent } from "@ionic/react";
import React, { useEffect, useState } from "react";

const Image = (props: any) => {
	const [imageUrl, setImageUrl] = useState("");
	useEffect(() => {
		if (props) {
			const CloudFrontUrl = "https://d2enogbnignv9r.cloudfront.net";
			const jsonImage = {
				bucket: "310viarspace52038-dev",
				key: props.path,
				edits: {
					resize: {
						width: 150,
						height: 150,
						fit: "cover",
					},
				},
			};

			const imageRequest = JSON.stringify(jsonImage);
			setImageUrl(CloudFrontUrl + "/" + btoa(imageRequest));
		}
	}, [props]);

	return <img src={imageUrl} width='100%' height='100%' />;
};
export default Image;
