/** @format */

import { AmplifySignOut } from "@aws-amplify/ui-react";
/** @format */

import {
	IonBackButton,
	IonBadge,
	IonButton,
	IonButtons,
	IonContent,
	IonFab,
	IonFabButton,
	IonHeader,
	IonIcon,
	IonInput,
	IonItem,
	IonLabel,
	IonList,
	IonMenuButton,
	IonModal,
	IonPage,
	IonSpinner,
	IonTitle,
	IonToolbar,
	useIonViewDidEnter,
	useIonViewWillLeave,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import ExploreContainer from "../../components/ExploreContainer";
import "../Page.css";
import MermaidContainer from "../../components/Mermaid";
import { add, person } from "ionicons/icons";
import { useDispatch, useSelector } from "react-redux";
import { setAuthState, userReducer } from "../../redux/userSlice";

import {
	getStudents,
	setStudent,
	setStudentsSubscription,
	studentsReducer,
} from "../../redux/studentsSlice";
const Students: React.FC = () => {
	const dispatch = useDispatch();
	const studentsSlice = useSelector(studentsReducer);
	const userSlice = useSelector(userReducer);
	const [showModal, setShowModal] = useState(false);
	const [newStudent, setNewStudent] = useState();
	useEffect(() => {
		if (userSlice.getAccount.id) {
			dispatch(getStudents(userSlice.getAccount.id));
		}
	}, [userSlice.getAccount.id]);

	useEffect(() => {
		if (newStudent) {
			setShowModal(false);
			dispatch(setStudent(newStudent));
		}
	}, [newStudent]);

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot='start'>
						<IonBackButton defaultHref='/app'></IonBackButton>
					</IonButtons>

					<IonButtons slot='end'>
						<IonMenuButton />
					</IonButtons>
					<IonTitle>Students</IonTitle>
				</IonToolbar>
			</IonHeader>

			<IonContent fullscreen>
				<IonHeader collapse='condense'>
					<IonToolbar>
						<IonTitle size='large'>Students</IonTitle>
					</IonToolbar>
				</IonHeader>

				<IonList>
					{studentsSlice.getStudents ? (
						<>
							{/* <JSONPretty
								data={studentsSlice.getStudents.items.items}></JSONPretty> */}
							{studentsSlice.getStudents.items.items.map((student: any) => (
								<IonItem
									routerLink={
										"/app/students/student/" + student.students.items[0].id
									}
									key={student.id}>
									{/* <JSONPretty data={student}></JSONPretty> */}
									<IonIcon icon={person} slot='start'></IonIcon>
									<IonLabel>
										<h3>
											{student.students.items[0].first_name}{" "}
											{student.students.items[0].last_name}
										</h3>
										<p>{student.students.items[0].id}</p>
									</IonLabel>
								</IonItem>
							))}
						</>
					) : null}
				</IonList>
				<IonFab horizontal='end' vertical='bottom' slot='fixed'>
					<IonFabButton onClick={(e) => setShowModal(true)}>
						<IonIcon icon={add}></IonIcon>
					</IonFabButton>
				</IonFab>
				<IonModal isOpen={showModal} cssClass='my-custom-class'>
					<IonHeader>
						<IonToolbar>
							<IonButtons slot='start'>
								<IonButton onClick={() => setShowModal(false)} fill='outline'>
									Close
								</IonButton>
							</IonButtons>
							<IonTitle>New Student</IonTitle>
						</IonToolbar>
					</IonHeader>

					<IonContent>
						<NewStudent setNewStudent={setNewStudent}></NewStudent>
					</IonContent>
				</IonModal>
			</IonContent>
		</IonPage>
	);
};
export default Students;

export const NewStudent = (props: any) => {
	interface NewStudent {
		first_name: string | null | undefined;
		last_name: string | null | undefined;
		email: string | null | undefined;
		altid: string | null | undefined;
	}
	const [student, setFrame] = useState<NewStudent>({
		first_name: "",
		last_name: "",
		email: "",
		altid: "",
	});
	return (
		<IonContent>
			<IonList>
				<IonItem>
					<IonLabel position='stacked'>First Name</IonLabel>
					<IonInput
						value={student.first_name}
						placeholder='The first name of the student'
						onIonChange={(e: any) =>
							setFrame({ ...student, first_name: e.target.value })
						}></IonInput>
				</IonItem>
				<IonItem>
					<IonLabel position='stacked'>Last Name</IonLabel>
					<IonInput
						value={student.last_name}
						placeholder='The last name of the student'
						onIonChange={(e: any) =>
							setFrame({ ...student, last_name: e.target.value })
						}></IonInput>
				</IonItem>
				<IonItem>
					<IonLabel position='stacked'>Email</IonLabel>
					<IonInput
						type='email'
						value={student.email}
						placeholder="The student's email"
						onIonChange={(e: any) =>
							setFrame({ ...student, email: e.target.value })
						}></IonInput>
				</IonItem>
				<IonItem>
					<IonLabel position='stacked'>ID</IonLabel>
					<IonInput
						value={student.altid}
						onIonChange={(e: any) =>
							setFrame({ ...student, altid: e.target.value })
						}
						placeholder='The alternaate Id of the student (less than 16 characters)'></IonInput>
					<IonBadge slot='end'>
						{student.altid ? student.altid.length : 0}/16
					</IonBadge>
				</IonItem>
				<IonButton
					expand='block'
					fill='outline'
					onClick={(e) => props.setNewStudent(student)}>
					Create
				</IonButton>
			</IonList>
		</IonContent>
	);
};
