/** @format */
import Amplify, { API, graphqlOperation, Hub } from "aws-amplify";
import awsconfig from "../aws-exports";

import { from, pipe } from "rxjs";
import { pluck } from "rxjs/operators";
import gql from "graphql-tag";
import { v4 as uuidv4 } from "uuid";
Amplify.configure(awsconfig);

export const studentsMiddle = (store) => (next) => (action) => {
	switch (action.type) {
		case "students/getStudents":
			from(
				API.graphql({
					query: gqlGetStudents,
					variables: {
						id: store.getState().user.getAccount.id,
					},
				})
			)
				.pipe(pluck("data", "getAccount", "studentAccounts"))
				.subscribe((res) => {
					store.dispatch({
						type: "students/setGetStudents",
						payload: res,
					});

					store.dispatch({
						type: "students/setStudentsSubscription",
						payload: true,
					});
				});

			break;
		case "students/setStudentsSubscription":
			if (action.payload) {
				console.log(
					"Subscribing",
					action.payload,
					store.getState().user.getAccount.id
				);

				API.graphql({
					query: gqlOnStudentChangeByOwnerAccount,
					variables: { accountID: store.getState().user.getAccount.id },
				}).subscribe({
					next: (data) => {
						store.dispatch({
							type: "students/getStudents",
							payload: store.getState().user.getAccount.id,
						});
					},
					error: (error) => {
						console.log("Error subscribing to student", error);
					},
				});
			} else {
				console.log("Unsubscribed from students");
				//studentsSubscription.unsubscribe();
			}

			break;
		case "students/setStudent":
			// accountID: store.getState().user.getAccount.id,
			const variables = {
				...action.payload,
				id: uuidv4(),
				accountID: store.getState().user.getAccount.id,
				studentAccountId: uuidv4(),
				userID: store.getState().user.getUser.id,
				status: "ACTIVE",
			};
			console.log(variables);

			from(
				API.graphql({
					query: gqlCreateStudent,
					variables: variables,
				})
			).subscribe(
				(next) => {
					console.log(next);
				},
				(error) => {
					console.log("Error", error);
				}
			);
		case "students/getStudent":
			from(
				API.graphql({
					query: gqlGetStudent,
					variables: { id: action.payload },
				})
			)
				.pipe(pluck("data", "getStudent"))
				.subscribe({
					next: (data) => {
						store.dispatch({
							type: "students/setStudentProfile",
							payload: data,
						});
					},
					error: (error) => {
						console.log("Error getting student", error);
					},
				});
			break;
		default:
			break;
	}

	return next(action);
};

const gqlGetStudent = gql`
	query getStudentQuery($id: ID!) {
		getStudent(id: $id) {
			email
			email_verified
			first_name
			id
			last_name
			ownerAccount
			phone_number
			phone_number_verified
			status
			updatedAt
			altid
		}
	}
`;

const gqlGetStudents = gql`
	query getStudentsQuery($id: ID!) {
		getAccount(id: $id) {
			studentAccounts {
				items {
					students {
						items {
							first_name
							last_name
							id
							status
						}
					}
					id
					status
				}
			}
		}
	}
`;

const gqlOnStudentAccountChangeByAccountId = gql`
	subscription onStudentAccountChangeByAccountIdSubscription($accountID: ID!) {
		onStudentAccountChangeByAccountId(accountID: $accountID) {
			id
		}
	}
`;

const gqlOnStudentChangeByOwnerAccount = gql`
	subscription onStudentChangeByOwnerAccountSubscription($accountID: ID) {
		onStudentChangeByOwnerAccount(ownerAccount: $accountID) {
			id
		}
	}
`;

const gqlCreateStudent = gql`
	mutation CreateStudent(
		$id: ID!
		$first_name: String
		$last_name: String
		$email: AWSEmail
		$altid: String
		$studentAccountId: ID!
		$userID: ID!
		$accountID: ID!
		$status: STATUS
	) {
		createStudent(
			input: {
				id: $id
				email: $email
				first_name: $first_name
				last_name: $last_name
				altid: $altid
				StudentAccountId: $studentAccountId
				userID: $userID
				ownerAccount: $accountID
				status: $status
			}
		) {
			id
			StudentAccountId
			ownerAccount
		}
		createStudentAccount(
			input: { id: $studentAccountId, status: ACTIVE, accountID: $accountID }
		) {
			id
			accountID
		}
	}
`;

/*
 */
