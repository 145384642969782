/** @format */

import {
	IonBackButton,
	IonBadge,
	IonButton,
	IonButtons,
	IonContent,
	IonFab,
	IonFabButton,
	IonHeader,
	IonIcon,
	IonInput,
	IonItem,
	IonLabel,
	IonList,
	IonMenuButton,
	IonModal,
	IonPage,
	IonTitle,
	IonToolbar,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
	coursesReducer,
	createCourse,
	initCourses,
} from "../../redux/coursesSlice";
import "../Page.css";

const CourseNew: React.FC = () => {
	const dispatch = useDispatch();
	const coursesSlice = useSelector(coursesReducer);
	interface Course {
		course_name: string | null | undefined;
		course_description: string | null | undefined;
		course_code: string | null | undefined;
	}
	const [course, setCourse] = useState<Course>({
		course_name: "",
		course_description: "",
		course_code: "",
	});
	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot='start'>
						<IonBackButton defaultHref='/app/courses'></IonBackButton>
					</IonButtons>
					<IonButtons slot='end'>
						<IonMenuButton />
					</IonButtons>
					<IonTitle>New Course</IonTitle>
				</IonToolbar>
			</IonHeader>

			<IonContent>
				<IonHeader collapse='condense'>
					<IonToolbar>
						<IonTitle size='large'>New Course</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonList>
					<IonItem>
						<IonLabel position='stacked'>Name</IonLabel>
						<IonInput
							value={course.course_name}
							placeholder='The name of the course'
							onIonChange={(e: any) =>
								setCourse({ ...course, course_name: e.target.value })
							}></IonInput>
					</IonItem>
					<IonItem>
						<IonLabel position='stacked'>Description</IonLabel>
						<IonInput
							value={course.course_description}
							placeholder='A short description of the course'
							onIonChange={(e: any) =>
								setCourse({ ...course, course_description: e.target.value })
							}></IonInput>
					</IonItem>
					<IonItem>
						<IonLabel position='stacked'>Code</IonLabel>

						<IonInput
							value={course.course_code}
							onIonChange={(e: any) =>
								setCourse({ ...course, course_code: e.target.value })
							}
							placeholder='The code of the course (less than 16 characters)'></IonInput>
						<IonBadge slot='end'>
							{course.course_code ? course.course_code.length : 0}/16
						</IonBadge>
					</IonItem>
				</IonList>

				<IonButton
					routerLink='/app/courses'
					routerDirection='back'
					class='ion-padding'
					expand='block'
					fill='outline'
					onClick={(e) => {
						dispatch(createCourse(course));
					}}>
					Create
				</IonButton>
			</IonContent>
		</IonPage>
	);
};
export default CourseNew;
