/** @format */

import { createSlice } from "@reduxjs/toolkit";

const studentSlice = createSlice({
	name: "students",
	initialState: {
		status: "BOOT",
		account: "",
		newStudent: {
			status: "INIT",
		},
		getStudents: {
			items: {
				items: [],
			},
		},
		getStudent: {},
		subscriptions: {
			students: false,
		},
	},
	reducers: {
		initStudents: (state, action) => {
			state.status = action.payload;
		},
		getStudents: (state, action) => {
			state.account = action.payload;
		},
		setGetStudents: (state, action) => {
			state.getStudents.items = action.payload;
		},
		setStudentsSubscription: (state, action) => {
			state.subscriptions.students = true;
		},
		setStudent: (state, action) => {
			state.newStudent.status = "CREATING";
		},
		getStudent: (state, action) => {},

		setStudentProfile: (state, action) => {
			state.getStudent = action.payload;
		},
	},
});

export const studentsReducer = (state) => state.students;
export const {
	initStudents,
	getStudents,
	setStudent,
	getStudent,
	setStudentsSubscription,
} = studentSlice.actions;

export default studentSlice.reducer;
