/** @format */

import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
	name: "user",
	initialState: {
		status: "INIT",
		authState: "",
		authData: {},
		getUser: {
			status: "INIT",
		},
		getAccount: {
			status: "INIT",
		},
		subscriptions: {
			account: "INIT",
			user: "INIT",
		},
	},
	reducers: {
		initUser: (state, action) => {
			state.status = action.payload;
		},
		setAuthState: (state, action) => {
			state.authState = action.payload;
		},
		setAuthData: (state, action) => {
			state.authData = action.payload;
		},
		setCreateUser: (state, action) => {
			state.getUser.status = "CREATING";
		},
		getAccount: (state, action) => {
			state.getAccount.status = action.payload.status;
		},
		setGetAccount: (state, action) => {
			state.getAccount = action.payload;
		},
		subscribeToAccount: (state, action) => {
			state.subscriptions.account = "SUBSCRIBED";
		},
		getUser: (state, action) => {
			state.getUser.status = "LOADING";
		},
		setGetUser: (state, action) => {
			state.getUser.status = "FORM";
			state.getUser = action.payload;
		},
		subscribeToUser: (state, action) => {
			state.subscriptions.user = "SUBSCRIBED";
		},
	},
});

export const userReducer = (state) => state.user;
export const {
	initUser,
	setAuthState,
	setAuthData,
	setCreateUser,
} = userSlice.actions;

export default userSlice.reducer;
