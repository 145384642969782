/** @format */

import { AmplifySignOut } from "@aws-amplify/ui-react";
/** @format */

import {
	IonBackButton,
	IonButton,
	IonButtons,
	IonContent,
	IonHeader,
	IonIcon,
	IonItem,
	IonList,
	IonMenuButton,
	IonPage,
	IonTitle,
	IonToolbar,
} from "@ionic/react";
import React from "react";
import { useParams } from "react-router";
import ExploreContainer from "../components/ExploreContainer";
import "./Page.css";
import MermaidContainer from "../components/Mermaid";
import Courses from "./Courses/Courses";
import { book, calendar, peopleCircle } from "ionicons/icons";
import MainMenuIcon from "../components/MainMenuIcon";
import { Link } from "react-router-dom";
const Page: React.FC = () => {
	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot='start'>
						<IonBackButton></IonBackButton>
					</IonButtons>
					<IonButtons slot='end'>
						<IonMenuButton />
					</IonButtons>
					<IonTitle>Viarspace</IonTitle>
				</IonToolbar>
			</IonHeader>

			<IonContent fullscreen>
				<IonHeader collapse='condense'>
					<IonToolbar>
						<IonTitle size='large'>Viarspace</IonTitle>
					</IonToolbar>
				</IonHeader>
				<Link to='/app/courses'>
					<MainMenuIcon img='courses.jpg' text='Courses'></MainMenuIcon>
				</Link>
				<Link to='/app/registrations'>
					<MainMenuIcon
						img='registration.jpg'
						text='Registrations'></MainMenuIcon>
				</Link>
				<Link to='/app/students'>
					<MainMenuIcon img='students.jpg' text='Students'></MainMenuIcon>
				</Link>
				{/* 
				<IonList>
					<IonItem routerLink='/Courses'>
						<IonIcon icon={book} slot='start'></IonIcon>Courses
					</IonItem>
					<IonItem routerLink='/Registrations'>
						<IonIcon icon={calendar} slot='start'></IonIcon>Registrations
					</IonItem>
					<IonItem routerLink='/Students'>
						<IonIcon icon={peopleCircle} slot='start'></IonIcon>Students
					</IonItem>
				</IonList>*/}
			</IonContent>
		</IonPage>
	);
};

export default Page;
