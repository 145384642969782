/** @format */

import { IonCol, IonContent, IonGrid, IonRow } from "@ionic/react";
/** @format */

import React from "react";
import "./public.css";

const Home = () => {
	return (
		<IonContent>
			<IonGrid class='ion-no-padding'>
				<IonRow>
					<IonCol>
						<div style={{ height: 100, backgroundColor: "silver" }}>Editor</div>
					</IonCol>
				</IonRow>
				<IonRow>
					<IonCol>
						<div style={{ height: 100, backgroundColor: "silver" }}>Player</div>
					</IonCol>
				</IonRow>
				<IonRow>
					<IonCol>
						<div style={{ height: 100, backgroundColor: "silver" }}>VR</div>
					</IonCol>
				</IonRow>
				<IonRow>
					<IonCol>
						<div style={{ height: 100, backgroundColor: "silver" }}>Store</div>
					</IonCol>
				</IonRow>

				<IonRow>
					<IonCol>
						<div style={{ height: 200, backgroundColor: "slategrey" }}>
							Easy to Use
						</div>
					</IonCol>
					<IonCol>
						<div style={{ height: 200, backgroundColor: "slategrey" }}>
							Any Device
						</div>
					</IonCol>
				</IonRow>
				<IonRow>
					<IonCol>
						<div style={{ height: 200, backgroundColor: "slategrey" }}>
							Multilingual
						</div>
					</IonCol>
					<IonCol>
						<img src='assets/img/public/homeapp.jpg'></img>
					</IonCol>
					<IonCol>Diploma</IonCol>
					<IonCol>Serverless</IonCol>
				</IonRow>
			</IonGrid>
		</IonContent>
	);
};

export default Home;
