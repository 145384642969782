/** @format */

import { IonCard, IonCardContent } from "@ionic/react";
import React, { useEffect, useState } from "react";

const FrameSettings: React.FC = () => {
	const [showModal, setShowModal] = useState(false);
	const [newFrame, setNewFrame] = useState();
	useEffect(() => {
		setShowModal(false);
	}, [newFrame]);
	return (
		<IonCard>
			<IonCardContent>Frame Settings information</IonCardContent>
		</IonCard>
	);
};
export default FrameSettings;
