/** @format */

import {
	IonBackButton,
	IonButtons,
	IonContent,
	IonHeader,
	IonIcon,
	IonMenuButton,
	IonPage,
	IonSegment,
	IonSegmentButton,
	IonToolbar,
	useIonViewWillEnter,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, useParams } from "react-router";

import "../Page.css";

import { easel, planet, settingsOutline } from "ionicons/icons";
import FrameScreen from "../../components/FrameScreen";
import FrameEnvironment from "../../components/FrameEnvironment";
import FrameSettings from "../../components/FrameSettings";
import { useDispatch, useSelector } from "react-redux";
import { frameReducer, initFrame, updateFrame } from "../../redux/frameSlice";
interface FrameProps
	extends RouteComponentProps<{
		frameid: string;
	}> {}

const Frame: React.FC<FrameProps> = ({ match }) => {
	const dispatch = useDispatch();
	const frameSlice = useSelector(frameReducer);
	const [showModal, setShowModal] = useState(false);
	const [newAsset, setNewAsset] = useState();
	const [assetType, setAssetType] = useState("");
	const [mode, setMode] = useState("SCREEN");

	useIonViewWillEnter(() => {
		dispatch(initFrame(match.params.frameid));
		//console.log("ionViewWillEnter event fired", match.params.frameid);
	}, [dispatch]);
	useEffect(() => {
		setShowModal(false);
	}, [newAsset]);
	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot='start'>
						<IonBackButton
							defaultHref={
								"/app/courses/course/" + match.params.frameid
							}></IonBackButton>
					</IonButtons>
					<IonButtons slot='end'>
						<IonMenuButton />
					</IonButtons>
					<IonSegment value={mode}>
						<IonSegmentButton value='SCREEN' onClick={(e) => setMode("SCREEN")}>
							<IonIcon icon={easel}></IonIcon>
						</IonSegmentButton>
						<IonSegmentButton
							value='ENVIRONMENT'
							onClick={(e) => setMode("ENVIRONMENT")}>
							<IonIcon icon={planet}></IonIcon>
						</IonSegmentButton>
						<IonSegmentButton
							value='SETTINGS'
							onClick={(e) => setMode("SETTINGS")}>
							<IonIcon icon={settingsOutline}></IonIcon>
						</IonSegmentButton>
					</IonSegment>
				</IonToolbar>
			</IonHeader>

			<IonContent fullscreen>
				{(() => {
					switch (mode) {
						case "SCREEN":
							return (
								<FrameScreen
									frame={frameSlice.getFrame}
									updateFrame={(e: any) => dispatch(updateFrame(e))}
								/>
							);
						case "ENVIRONMENT":
							return <FrameEnvironment />;
						case "SETTINGS":
							return <FrameSettings />;
					}
				})()}
			</IonContent>
		</IonPage>
	);
};
export default Frame;
