/** @format */

// Redux
import { configureStore } from "@reduxjs/toolkit";
import appSlice from "./appSlice";
import userSlice from "./userSlice";
import studentsSlice from "./studentsSlice";
import coursesSlice from "./coursesSlice";
import courseSlice from "./courseSlice";
import frameSlice from "./frameSlice";
import { frameMiddle } from "./frameMiddle";
import { coursesMiddle } from "./coursesMiddle";
import { courseMiddle } from "./courseMiddle";
import { appMiddle } from "./appMiddle";
import { userMiddle } from "./userMiddle";
import { studentsMiddle } from "./studentsMiddle";

export default configureStore({
	reducer: {
		app: appSlice,
		user: userSlice,
		students: studentsSlice,
		courses: coursesSlice,
		course: courseSlice,
		frame: frameSlice,
	}, // TODO: Configure courses, students, registrations
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware()
			.prepend(
				appMiddle,
				userMiddle,
				studentsMiddle,
				coursesMiddle,
				courseMiddle,
				frameMiddle
			)
			.concat(),
	devTools: true,
});
