/** @format */

import { createSlice } from "@reduxjs/toolkit";

const coursesSlice = createSlice({
	name: "courses",
	initialState: {
		status: "boot",
		courses: {
			status: "INIT",
			items: [],
		},
		newcourse: {
			status: "INIT",
		},
		subscriptions: {
			courses: "INIT",
		},
	},
	reducers: {
		initCourses: (state, action) => {
			state.status = action.payload;
		},
		getCourses: (state, action) => {
			state.courses.status = action.payload;
		},
		setCourses: (state, action) => {
			state.courses.items = action.payload;
			state.courses.status = "LOADED";
		},
		createCourse: (state, action) => {
			state.newcourse.status = "CREATING";
		},
		setCreatedCourse: (state, action) => {
			state.newcourse.status = "CREATED";
		},
		setCourseSubscription: (state, action) => {
			state.subscriptions.courses = action.payload;
		},
	},
});

export const coursesReducer = (state) => state.courses;
export const { initCourses, createCourse } = coursesSlice.actions;

export default coursesSlice.reducer;
