/** @format */

import {
	IonButton,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardTitle,
	IonCol,
	IonContent,
	IonGrid,
	IonPage,
	IonRow,
	IonSegment,
	IonSegmentButton,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { add } from "ionicons/icons";
import { appReducer } from "../redux/appSlice";
import JSONPretty from "react-json-pretty";
import Image from "./Image";
import AWS from "aws-sdk";
import awsmobile from "../aws-exports";
const Player = (props: any) => {
	const appSlice = useSelector(appReducer);
	const [frame, setFrame] = useState<any>({});

	useEffect(() => {
		if (props.frame) {
			setFrame(props.frame);
		}
	}, [props.frame]);

	return (
		<div
			style={{
				width: "100",
				backgroundColor: "blue",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				height:
					window.screen.height > window.screen.width
						? window.screen.width * 0.5
						: "100%",
			}}>
			{(() => {
				switch (frame.type) {
					case "IMAGETEXT":
						return <ImagetextPlayer frame={frame}></ImagetextPlayer>;
					case "VIDEO":
						return <VideoPlayer frame={frame}></VideoPlayer>;
					case "QUESTION":
						return <QuestionPlayer frame={frame}></QuestionPlayer>;
				}
			})()}
		</div>
	);
};
export default Player;

export const VideoPlayer = (props: any) => {
	return <>VIDEO</>;
};

export const ImagetextPlayer = (props: any) => {
	const [frame, setFrame] = useState<any>({});
	useEffect(() => {
		setFrame(props.frame);
	}, [props.frame]);
	useEffect(() => {
		if (frame.imagetext_speech) {
			//console.log("speech", frame.imagetext_speech);
		}
	}, [frame]);
	return (
		<IonGrid style={{ padding: 0 }}>
			<IonRow style={{ padding: 0 }}>
				<IonCol
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						fontSize: "4vmin",
						fontWeight: "bold",
						color: "goldenrod",
					}}>
					{frame.imagetext_text}
				</IonCol>
				{frame.imagetext_image ? (
					<IonCol style={{ padding: 0 }}>
						<Image path={"public/" + frame.imagetext_image}></Image>
					</IonCol>
				) : null}
			</IonRow>
		</IonGrid>
	);
};

export const QuestionPlayer = (props: any) => {
	const [frame, setFrame] = useState<any>({});
	useEffect(() => {
		setFrame(props.frame);
	}, [props.frame]);
	return (
		<IonGrid>
			<IonRow>
				<IonCol>
					<div className='ion-text-center'>{frame.question_question}</div>
				</IonCol>
			</IonRow>
			<IonRow>
				<AnswerPlayer answer={frame.question_answer1}></AnswerPlayer>
			</IonRow>
			<IonRow>
				<AnswerPlayer answer={frame.question_answer2}></AnswerPlayer>
			</IonRow>
			<IonRow>
				<AnswerPlayer answer={frame.question_answer3}></AnswerPlayer>
			</IonRow>
			<IonRow>
				<AnswerPlayer answer={frame.question_answer4}></AnswerPlayer>
			</IonRow>
			<IonRow>
				<AnswerPlayer answer={frame.question_answer5}></AnswerPlayer>
			</IonRow>
		</IonGrid>
	);
};

export const AnswerPlayer = (props: any) => {
	const [selected, setSelected] = useState(false);
	return (
		<>
			{
				<>
					{props.answer ? (
						<IonButton
							style={{ width: "100%" }}
							size='small'
							expand='block'
							fill={selected ? "solid" : "outline"}
							onClick={(e) => setSelected(!selected)}>
							{props.answer}
						</IonButton>
					) : null}
				</>
			}
		</>
	);
};
