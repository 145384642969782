/** @format */

import {
	IonButton,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardTitle,
	IonContent,
	IonFab,
	IonFabButton,
	IonIcon,
	IonItemDivider,
	IonPage,
	IonSegment,
	IonSegmentButton,
	IonTextarea,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import createToolbarPlugin from "draft-js-static-toolbar-plugin";
import { add, cloudUpload, play, save } from "ionicons/icons";
import FileUploader from "../components/FileUploader";
const FrameImageText = (props: any) => {
	const [frame, setFrame] = useState<any>({});
	useEffect(() => {
		setFrame(props.frame);
	}, [props]);
	useEffect(() => {}, [frame]);
	const [type, setTypes] = useState([
		{
			name: "IMAGE",
			icon: "image",
		},
		{
			name: "TEXT",
			icon: "text",
		},
		{
			name: "VOICE",
			icon: "chatbubble",
		},
	]);
	const toolbarPlugin = createToolbarPlugin();
	const [mode, setMode] = useState("TEXT");
	return (
		<>
			<IonButton expand='block' fill='outline'>
				<IonIcon icon={play}></IonIcon>
			</IonButton>
			<IonCard>
				<IonSegment value={mode}>
					{type.map((type) => (
						<IonSegmentButton
							value={type.name}
							onClick={(e: any) => setMode(type.name)}
							key={type.name}>
							{type.name}
						</IonSegmentButton>
					))}
				</IonSegment>

				<IonCardContent>
					<>
						{(() => {
							switch (mode) {
								case "TEXT":
									return (
										<div
											style={{
												display: "flex",
												flexDirection: "column",
												alignItems: "center",
											}}>
											<IonTextarea
												style={{
													borderWidth: 1,
													borderStyle: "solid",
												}}
												value={frame.imagetext_text}
												onIonChange={(e: any) =>
													setFrame({
														...frame,
														imagetext_text: e.target.value,
													})
												}></IonTextarea>
											<IonItemDivider />
											<div>
												<IonFabButton
													onClick={(e: any) => props.updateFrame(frame)}>
													<IonIcon icon={save}></IonIcon>
												</IonFabButton>
											</div>

											{/* <IonButton
												expand='block'
												fill='outline'
												onClick={(e: any) => props.updateFrame(frame)}>
												<IonIcon icon={cloudUpload}></IonIcon>
											</IonButton> */}
										</div>
									);

								case "IMAGE":
									return (
										<FileUploader
											file={frame.imagetext_image}
											onUpload={(e: any) => {
												props.updateFrame({ ...frame, imagetext_image: e.key });
											}}></FileUploader>
									);

								case "VOICE":
									return (
										<>
											<IonTextarea
												value={frame.imagetext_speech}
												onIonChange={(e: any) =>
													setFrame({
														...frame,
														imagetext_speech: e.target.value,
													})
												}></IonTextarea>
											<IonButton
												expand='block'
												fill='outline'
												onClick={(e: any) => props.updateFrame(frame)}>
												<IonIcon icon={cloudUpload}></IonIcon>
											</IonButton>
										</>
									);
									break;
							}
						})()}
					</>
				</IonCardContent>
			</IonCard>
		</>
	);
};
export default FrameImageText;
