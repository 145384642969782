/** @format */

import gql from "graphql-tag";
export const ListCourses = gql`
	query getAccountQuery($accountID: ID!) {
		getAccount(id: $accountID) {
			courseAccounts {
				items {
					courses {
						items {
							id
							course_description
							course_name
							course_status
						}
					}
				}
			}
		}
	}
`;

export const SubscribeToCourseAccounts = gql`
	subscription SubscribeToCourseAccounts($accountID: ID!) {
		onCourseAccountChangeByAccountId(accountID: $accountID) {
			id
		}
	}
`;

export const NewCourse = gql`
	mutation NewCourseMutation(
		$id: ID!
		$userID: ID!
		$course_status: String
		$course_name: String!
		$course_description: String
		$CourseAccountId: ID!
		$accountID: ID!
	) {
		createCourse(
			input: {
				id: $id
				userID: $userID
				status: ACTIVE
				course_status: $course_status
				course_name: $course_name
				course_description: $course_description
				CourseAccountId: $CourseAccountId
			}
		) {
			userID
			status
			id
			course_status
			course_name
			course_description
			CourseAccountId
		}
		createCourseAccount(
			input: { accountID: $accountID, id: $CourseAccountId, status: ACTIVE }
		) {
			id
			status
			accountID
		}
	}
`;
