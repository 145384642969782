/** @format */

import { createSlice } from "@reduxjs/toolkit";

const appSlice = createSlice({
	name: "app",
	initialState: {
		status: "boot",
		awsconfig: {},
		browser: {},
		ip: "",
		hub: {
			UIAuth: {
				message: "init",
			},
		},
		location: {},
		screen: {},
		getApp: {},
		error: {
			show: false,
			title: "",
			message: "",
			error: {},
		},
	},
	reducers: {
		initApp: (state, action) => {
			state.status = action.payload;
		},
		setError: (state, action) => {
			state.error = {
				...action.payload,
				show: true,
			};
		},
		setDismissError: (state) => {
			state.error.show = false;
		},
		setAwsconfig: (state, action) => {
			state.awsconfig = action.payload;
		},
		setBrowser: (state, action) => {
			state.browser = action.payload;
		},
		setLocation: (state, action) => {
			state.location = action.payload;
		},
		setScreen: (state, action) => {
			state.screen = action.payload;
		},
		setIp: (state, action) => {
			state.ip = action.payload;
		},
		setGetApp: (state, action) => {
			state.getApp = action.payload;
		},
		setHub: (state, action) => {
			state.hub[action.payload.channel.replace(" ", "")] =
				action.payload.payload;
		},
	},
});

export const appReducer = (state) => state.app;
export const { initApp, setDismissError } = appSlice.actions;

export default appSlice.reducer;
