/** @format */

import { createSlice } from "@reduxjs/toolkit";

const frameSlice = createSlice({
	name: "frame",
	initialState: {
		status: "boot",
		frameid: "",
		getFrame: {
			type: "",
		},
	},
	reducers: {
		initFrame: (state, action) => {
			state.status = "INIT";
			state.frameid = action.payload;
		},
		getFrame: (state, action) => {},
		setFrame: (state, action) => {
			state.getFrame = action.payload;
		},
		updateFrame: (state, action) => {},
	},
});

export const frameReducer = (state) => state.frame;
export const { initFrame, updateFrame } = frameSlice.actions;

export default frameSlice.reducer;
