/** @format */

import { IonSegment, IonSegmentButton } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, useParams } from "react-router";
import FrameImageText from "./FrameImagetext";
import FrameVideo from "./FrameVideo";
import FrameQuestion from "./FrameQuestion";

import Player from "./Player";

const FrameScreen = (props: any) => {
	const [languages, setLanguages] = useState([
		"en",
		"es",
		"fr",
		"de",
		"it",
		"jp",
		"ru",
		"cn",
	]);
	const [language, setLanguage] = useState("en");
	const [frameType, setFrameType] = useState("IMAGETEXT");
	const [frame, setFrame] = useState<any>();
	useEffect(() => {
		setFrame(props.frame);
	}, [props]);
	return (
		<>
			<IonSegment scrollable={true} value={language}>
				{languages.map((language) => (
					<IonSegmentButton
						key={language}
						value={language}
						onClick={(e) => setLanguage(language)}>
						{language.toUpperCase()}
					</IonSegmentButton>
				))}
			</IonSegment>
			<Player frame={frame}></Player>
			{frame ? (
				<>
					{(() => {
						switch (frame.type) {
							case "IMAGETEXT":
								return (
									<FrameImageText
										frame={frame}
										updateFrame={props.updateFrame}
									/>
								);

							case "VIDEO":
								return <FrameVideo />;

							case "QUESTION":
								return (
									<FrameQuestion
										frame={frame}
										updateFrame={props.updateFrame}
									/>
								);
							default:
								return <div>Frame Type Not Defined</div>;
						}
					})()}
				</>
			) : null}
		</>
	);
};
export default FrameScreen;
