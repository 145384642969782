/** @format */

import {
	IonButton,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardTitle,
	IonCheckbox,
	IonContent,
	IonFab,
	IonFabButton,
	IonIcon,
	IonInput,
	IonItem,
	IonItemDivider,
	IonLabel,
	IonList,
	IonPage,
	IonSegment,
	IonSegmentButton,
	IonText,
	IonTextarea,
	IonToggle,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

import { add, cloudUpload, play, save } from "ionicons/icons";

const FrameQuestion = (props: any) => {
	const [frame, setFrame] = useState<any>({});
	useEffect(() => {
		setFrame(props.frame);
	}, [props]);
	return (
		<>
			<IonList>
				<IonItem>
					<IonInput
						placeholder='Question'
						value={frame.question_question}
						onIonChange={(e: any) =>
							setFrame({ ...frame, question_question: e.target.value })
						}></IonInput>
				</IonItem>
				<IonItem>
					<IonCheckbox
						checked={frame.question_answer1_correct}
						onIonChange={(e: any) =>
							setFrame({ ...frame, question_answer1_correct: e.detail.checked })
						}
						slot='start'></IonCheckbox>
					<IonInput
						placeholder='Answer 1'
						value={frame.question_answer1}
						onIonChange={(e: any) =>
							setFrame({ ...frame, question_answer1: e.target.value })
						}></IonInput>
				</IonItem>
				<IonItem>
					<IonCheckbox
						checked={frame.question_answer2_correct}
						onIonChange={(e: any) =>
							setFrame({ ...frame, question_answer2_correct: e.detail.checked })
						}
						slot='start'></IonCheckbox>
					<IonInput
						placeholder='Answer 2'
						value={frame.question_answer2}
						onIonChange={(e: any) =>
							setFrame({ ...frame, question_answer2: e.target.value })
						}></IonInput>
				</IonItem>
				<IonItem>
					<IonCheckbox
						slot='start'
						checked={frame.question_answer3_correct}
						onIonChange={(e: any) =>
							setFrame({ ...frame, question_answer3_correct: e.detail.checked })
						}></IonCheckbox>
					<IonInput
						placeholder='Answer 3'
						value={frame.question_answer3}
						onIonChange={(e: any) =>
							setFrame({ ...frame, question_answer3: e.target.value })
						}></IonInput>
				</IonItem>
				<IonItem>
					<IonCheckbox
						slot='start'
						checked={frame.question_answer4_correct}
						onIonChange={(e: any) =>
							setFrame({ ...frame, question_answer4_correct: e.detail.checked })
						}></IonCheckbox>
					<IonInput
						placeholder='Answer 4'
						value={frame.question_answer4}
						onIonChange={(e: any) =>
							setFrame({ ...frame, question_answer4: e.target.value })
						}></IonInput>
				</IonItem>
				<IonItem>
					<IonCheckbox
						slot='start'
						checked={frame.question_answer5_correct}
						onIonChange={(e: any) =>
							setFrame({ ...frame, question_answer5_correct: e.detail.checked })
						}></IonCheckbox>
					<IonInput
						placeholder='Answer 5'
						value={frame.question_answer5}
						onIonChange={(e: any) =>
							setFrame({ ...frame, question_answer5: e.target.value })
						}></IonInput>
				</IonItem>
			</IonList>
			<IonItemDivider>Configuration </IonItemDivider>
			<IonItem>
				<IonLabel>Graded</IonLabel>
				<IonToggle
					value='true'
					slot='start'
					checked={frame.question_graded}
					onIonChange={(e: any) =>
						setFrame({ ...frame, question_graded: e.detail.checked })
					}></IonToggle>
				<IonText slot='end'>(Test)</IonText>
			</IonItem>
			<IonItem>
				<IonLabel position='stacked'>Remediation</IonLabel>
				<IonTextarea
					autoGrow={true}
					onIonChange={(e: any) =>
						setFrame({ ...frame, question_remediation: e.target.value })
					}></IonTextarea>
			</IonItem>

			<IonFab horizontal='end' vertical='bottom' slot='fixed'>
				<IonFabButton onClick={(e: any) => props.updateFrame(frame)}>
					<IonIcon icon={cloudUpload}></IonIcon>
				</IonFabButton>
			</IonFab>
		</>
	);
};
export default FrameQuestion;
