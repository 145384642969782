/** @format */

import { AmplifyAuthenticator, AmplifySignOut } from "@aws-amplify/ui-react";
import { IonButton, IonContent, IonSpinner } from "@ionic/react";
import NewUser from "../components/NewUser";
/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCreateUser, userReducer } from "../redux/userSlice";

const Login: React.FC = () => {
	const dispatch = useDispatch();
	const [newUser, setNewUser] = useState<any>({ status: "FORM" });
	const userSlice = useSelector(userReducer);
	useEffect(() => {
		if (newUser.status !== "FORM") {
			dispatch(setCreateUser(newUser));
		}
	}, [dispatch, newUser]);
	return (
		<>
			<AmplifyAuthenticator>
				<IonContent>
					{userSlice.getUser.status !== "ACTIVE" ? (
						<>
							{userSlice.getUser.status === "FORM" ? (
								<NewUser setNewUser={setNewUser}></NewUser>
							) : (
								<IonSpinner></IonSpinner>
							)}
						</>
					) : (
						<IonSpinner></IonSpinner>
					)}
				</IonContent>
			</AmplifyAuthenticator>
		</>
	);
};

export default Login;
