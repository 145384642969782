/** @format */
import Amplify, { API, graphqlOperation } from "aws-amplify";
import awsconfig from "../aws-exports";
import AWSAppSyncClient, { AUTH_TYPE } from "aws-appsync";
import { from, fromEvent, pipe } from "rxjs";
import { pluck, tap } from "rxjs/operators";
import Auth from "@aws-amplify/auth";
import { watch } from "rxjs-watcher";
import { v4 as uuidv4 } from "uuid";
import gql from "graphql-tag";
import { tabletLandscape } from "ionicons/icons";
Amplify.configure(awsconfig);
const client = new AWSAppSyncClient({
	url: awsconfig.aws_appsync_graphqlEndpoint,
	region: awsconfig.aws_appsync_region,
	auth: {
		type: AUTH_TYPE.API_KEY, // or type: awsconfig.aws_appsync_authenticationType,
		apiKey: awsconfig.aws_appsync_apiKey,
	},
});
export const userMiddle = (store) => (next) => (action) => {
	switch (action.type) {
		case "user/initUser":
			break;

		case "user/setCreateUser":
			let variables = {
				...action.payload,
				...store.getState().app.hub.UIAuth.data.attributes,
				appID: store.getState().app.location.host,
				UserAccountId: uuidv4(),
				accountID: uuidv4(),
				id: store.getState().app.hub.UIAuth.data.attributes.sub,
				StudentAccountId: uuidv4(),
				StudentID: uuidv4(),
				status: "ACTIVE",
			};

			from(
				API.graphql({
					query: gqlCreateNewUser,
					variables: variables,
				})
			).subscribe(
				(next) => {
					store.dispatch({
						type: "user/getAccount",
						payload: {
							id: next.data.createAccount.id,
						},
					});
					store.dispatch({
						type: "user/subscribeToAccount",
						payload: {
							id: next.data.createAccount.id,
						},
					});
					store.dispatch({
						type: "user/getUser",
						payload: {
							id: next.data.createUser.id,
						},
					});
					store.dispatch({
						type: "user/subscribeToUser",
						payload: {
							id: next.data.createUser.id,
						},
					});
				},
				(err) => {
					store.dispatch({
						type: "app/setError",
						payload: {
							title: "Error creating new user",
							message: err,
						},
					});
					console.log("An error has occurred creating the user", err);
				}
			);
			break;
		case "user/subscribeToAccount":
			// Subscribe to Account
			API.graphql({
				query: gqlOnAccountChangeById,
				variables: action.payload,
			}).subscribe(
				(next) => {
					// console.log("NEXY", next);
					// store.dispatch({
					// 	type: "user/getAccount",
					// 	payload: {
					// 		id: next.value.data.onAccountChangeById.id,
					// 	},
					// });
				},
				(error) => {
					console.log("Error subscribing to account", error);
				}
			);
			break;
		case "user/subscribeToUser":
			// Subscribe to User
			API.graphql({
				query: gqlOnUserChangeById,
				variables: action.payload,
			}).subscribe(
				(next) => {
					store.dispatch({
						type: "user/getUser",
						payload: {
							id: next.value.data.onUserChangeById.id,
						},
					});
				},
				(error) => {
					console.log("Error subscribing to user", error);
				}
			);
			break;
		case "user/getAccount":
			from(
				API.graphql({
					query: gqlGetAccount,
					variables: action.payload,
				})
			).subscribe((getAccountResult) => {
				store.dispatch({
					type: "user/setGetAccount",
					payload: getAccountResult.data.getAccount,
				});
			});
			break;
		case "user/getUser":
			from(
				API.graphql({
					query: gqlGetUser,
					variables: action.payload,
				})
			).subscribe((getUserResult) => {
				if (getUserResult.data.getUser) {
					store.dispatch({
						type: "user/setGetUser",
						payload: getUserResult.data.getUser,
					});
					store.dispatch({
						type: "user/subscribeToUser",
						payload: {
							id: getUserResult.data.getUser.id,
						},
					});

					store.dispatch({
						type: "user/getAccount",
						payload: {
							id: getUserResult.data.getUser.userAccount.accountID,
						},
					});
					store.dispatch({
						type: "user/subscribeToAccount",
						payload: {
							id: getUserResult.data.getUser.userAccount.accountID,
						},
					});
				} else {
					store.dispatch({
						type: "user/setGetUser",
						payload: { status: "FORM" },
					});
				}
			});
			break;
		default:
	}

	return next(action);
};

const gqlCreateNewUser = gql`
	mutation creteUser(
		$id: ID!
		$status: STATUS
		$UserAccountId: ID!
		$accountID: ID!
		$appID: ID!
		$StudentAccountId: ID!
		$StudentID: ID!
		$sub: ID!
		$account_name: String!
		$email: AWSEmail
		$email_verified: Boolean
		$first_name: String
		$last_name: String
		$phone_number: String
		$phone_number_verified: Boolean
	) {
		createAccount(
			input: {
				appID: $appID
				status: $status
				id: $accountID
				name: $account_name
			}
		) {
			id
			status
		}
		createUser(
			input: {
				id: $id
				status: $status
				UserAccountId: $UserAccountId
				email: $email
				email_verified: $email_verified
				first_name: $first_name
				last_name: $last_name
				phone_number: $phone_number
				phone_number_verified: $phone_number_verified
				sub: $sub
			}
		) {
			id
			status
			sub
			phone_number_verified
			phone_number
			last_name
			first_name
			email_verified
			email
			createdAt
			UserAccountId
		}
		createUserAccount(
			input: { status: $status, accountID: $accountID, id: $UserAccountId }
		) {
			id
			status
		}
		createStudent(
			input: {
				id: $StudentID
				status: $status
				StudentAccountId: $StudentAccountId
				email: $email
				email_verified: $email_verified
				first_name: $first_name
				last_name: $last_name
				phone_number: $phone_number
				phone_number_verified: $phone_number_verified
				sub: $sub
				userID: $id
			}
		) {
			id
			status
		}
		createStudentAccount(
			input: { accountID: $accountID, status: $status, id: $StudentAccountId }
		) {
			id
			status
		}
	}
`;

const gqlGetAccount = gql`
	query getAccountQuery($id: ID!) {
		getAccount(id: $id) {
			id
			status
			name
		}
	}
`;

const gqlGetUser = gql`
	query getUserQuery($id: ID!) {
		getUser(id: $id) {
			id
			status
			email
			first_name
			email_verified
			last_name
			phone_number
			phone_number_verified
			sub
			userAccount {
				accountID
			}
		}
	}
`;

const gqlOnAccountChangeById = gql`
	subscription onAccountChangeByIdSubscription($id: ID!) {
		onAccountChangeById(id: $id) {
			id
		}
	}
`;

const gqlOnUserChangeById = gql`
	subscription onUserChangeByIdSubscription($id: ID!) {
		onUserChangeById(id: $id) {
			id
		}
	}
`;
