/** @format */

import { AmplifySignOut } from "@aws-amplify/ui-react";
/** @format */

import {
	IonAlert,
	IonBackButton,
	IonBadge,
	IonButton,
	IonButtons,
	IonContent,
	IonFab,
	IonFabButton,
	IonFabList,
	IonHeader,
	IonIcon,
	IonInput,
	IonItem,
	IonLabel,
	IonList,
	IonMenuButton,
	IonModal,
	IonPage,
	IonSpinner,
	IonTitle,
	IonToolbar,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, useParams } from "react-router";
import ExploreContainer from "../../components/ExploreContainer";
import "../Page.css";
import MermaidContainer from "../../components/Mermaid";
import { add, help, image, tv, videocam } from "ionicons/icons";
import { useDispatch, useSelector } from "react-redux";
import { courseReducer, initCourse } from "../../redux/courseSlice";
import { setFrameNew } from "../../redux/courseSlice";
import JSONPretty from "react-json-pretty";
interface CourseProps
	extends RouteComponentProps<{
		id: string;
	}> {}
const Course: React.FC<CourseProps> = ({ match, history }) => {
	const dispatch = useDispatch();
	const courseSlice = useSelector(courseReducer);
	const [showModal, setShowModal] = useState(false);
	const [newFrame, setNewFrame] = useState();
	const [newFrameName, setNewFrameName] = useState();
	const [frameType, setFrameType] = useState("");
	const [showFrameNamePrompt, setShowFrameNamePrompt] = useState(false);
	useEffect(() => {
		dispatch(initCourse(match.params.id));
	}, []);
	useEffect(() => {
		if (frameType) {
			setShowFrameNamePrompt(true);
		}
	}, [frameType]);
	useEffect(() => {
		if (newFrameName) {
			dispatch(
				setFrameNew({ FrameName: newFrameName, order: 1, FrameType: frameType })
			);
			setFrameType("");
			history.push("/app/courses/course/frame/" + match.params.id);
		}
	}, [newFrameName]);
	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot='start'>
						<IonBackButton defaultHref='/app/courses/'></IonBackButton>
					</IonButtons>
					<IonButtons slot='end'>
						<IonMenuButton />
					</IonButtons>
					<IonTitle>Course</IonTitle>
				</IonToolbar>
			</IonHeader>

			<IonContent fullscreen>
				<IonHeader collapse='condense'>
					<IonToolbar>
						<IonTitle size='large'>Course</IonTitle>
					</IonToolbar>
				</IonHeader>

				<IonList>
					{courseSlice.frames.items ? (
						<>
							{courseSlice.frames.items.map((frame: any) => (
								<IonItem
									routerLink={
										"/app/courses/course/frame/" + frame.frames.items[0].id
									}
									key={frame.id}>
									<IonIcon icon={image} slot='start'></IonIcon>
									<IonLabel>{frame.frames.items[0].name}</IonLabel>{" "}
								</IonItem>
							))}
						</>
					) : (
						<div className='center'>
							<IonSpinner></IonSpinner>
						</div>
					)}
				</IonList>
				<IonFab horizontal='end' vertical='bottom' slot='fixed'>
					<IonFabButton>
						<IonIcon icon={add}></IonIcon>
					</IonFabButton>
					<IonFabList side='top'>
						<IonFabButton onClick={(e) => setFrameType("VIDEO")}>
							<IonIcon icon={videocam} />
						</IonFabButton>
						<IonFabButton onClick={(e) => setFrameType("QUESTION")}>
							<IonIcon icon={help} />
						</IonFabButton>
						<IonFabButton onClick={(e) => setFrameType("IMAGETEXT")}>
							<IonIcon icon={image} />
						</IonFabButton>
					</IonFabList>
				</IonFab>
				<IonAlert
					isOpen={showFrameNamePrompt}
					onDidDismiss={() => setShowFrameNamePrompt(false)}
					cssClass='my-custom-class'
					header={"New Frame"}
					subHeader={"Create new " + frameType}
					message={"Enter the name of the new frame."}
					inputs={[
						{
							name: "FrameName",
							type: "text",
						},
					]}
					buttons={[
						{
							text: "Cancel",
							role: "cancel",
							cssClass: "secondary",
							handler: () => {
								setShowFrameNamePrompt(false);
								console.log("Confirm Cancel");
							},
						},
						{
							text: "Create",
							handler: (Frame) => {
								setShowFrameNamePrompt(false);
								setNewFrameName(Frame.FrameName);
							},
						},
					]}
				/>
				<IonModal isOpen={showModal} cssClass='my-custom-class'>
					<IonHeader>
						<IonToolbar>
							<IonButtons slot='start'>
								<IonButton onClick={() => setShowModal(false)} fill='outline'>
									Close
								</IonButton>
							</IonButtons>
							<IonTitle>Course</IonTitle>
						</IonToolbar>
					</IonHeader>

					<IonContent>
						<NewFrame setNewFrame={setNewFrame}></NewFrame>
					</IonContent>
				</IonModal>
			</IonContent>
		</IonPage>
	);
};
export default Course;

export const NewFrame = (props: any) => {
	interface NewFrame {
		name: string | null | undefined;
		description: string | null | undefined;
		code: string | null | undefined;
	}
	const [frame, setFrame] = useState<NewFrame>({
		name: "",
		description: "",
		code: "",
	});
	return (
		<IonContent>
			<IonList>
				<IonItem>
					<IonLabel position='stacked'>Name</IonLabel>
					<IonInput
						value={frame.name}
						placeholder='The name of the frame'
						onIonChange={(e: any) =>
							setFrame({ ...frame, name: e.target.value })
						}></IonInput>
				</IonItem>
				<IonItem>
					<IonLabel position='stacked'>Description</IonLabel>
					<IonInput
						value={frame.description}
						placeholder='A short description of the frame'
						onIonChange={(e: any) =>
							setFrame({ ...frame, description: e.target.value })
						}></IonInput>
				</IonItem>
				<IonItem>
					<IonLabel position='stacked'>Code</IonLabel>
					<IonInput
						value={frame.code}
						onIonChange={(e: any) =>
							setFrame({ ...frame, code: e.target.value })
						}
						placeholder='The code of the frame (less than 16 characters)'></IonInput>
					<IonBadge slot='end'>
						{frame.code ? frame.code.length : 0}/16
					</IonBadge>
				</IonItem>
				<IonButton
					expand='block'
					fill='outline'
					onClick={(e) => props.setNewFrame(frame)}>
					Create
				</IonButton>
			</IonList>
		</IonContent>
	);
};
