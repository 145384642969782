/** @format */

import { createSlice } from "@reduxjs/toolkit";

const courseSlice = createSlice({
	name: "course",
	initialState: {
		status: "boot",
		id: "",
		frames: {
			status: "INIT",
			items: [],
		},
		new: {},
	},
	reducers: {
		initCourse: (state, action) => {
			state.status = "INIT";
		},
		setCourseId: (state, action) => {
			state.id = action.payload;
		},
		setFrameNew: (state, action) => {
			//console.log("action", action);
			state.new = action.payload;
		},
		getFrames: (state) => {
			state.frames.status = "LOADING";
		},
		setFrames: (state, action) => {
			state.frames.items = action.payload;
		},
		subscribeToFrames: (state, action) => {},
	},
});

export const courseReducer = (state) => state.course;
export const { initCourse, setFrameNew } = courseSlice.actions;

export default courseSlice.reducer;
