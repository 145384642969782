/** @format */

import {
	IonButton,
	IonContent,
	IonHeader,
	IonInput,
	IonItem,
	IonLabel,
	IonList,
	IonListHeader,
	IonTitle,
	IonToolbar,
} from "@ionic/react";

import React, { useState } from "react";
import "./ExploreContainer.css";

const NewUser = (props: any) => {
	interface NewUser {
		first_name: string | null | undefined;
		last_name: string | null | undefined;
		account_name: string | null | undefined;
	}
	const [newUser, setNewUser] = useState<NewUser>({
		first_name: "",
		last_name: "",
		account_name: "",
	});
	return (
		<>
			<IonHeader>
				<IonToolbar>
					<IonTitle>New User</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<IonList>
					<IonListHeader>User</IonListHeader>
					<IonItem>
						<IonLabel position='stacked'>First Name</IonLabel>
						<IonInput
							min='2'
							value={newUser.first_name}
							onIonChange={(e: any) =>
								setNewUser({
									...newUser,
									first_name: e.detail.value,
								})
							}></IonInput>
					</IonItem>
					<IonItem>
						<IonLabel position='stacked'>Last Name</IonLabel>
						<IonInput
							min='2'
							value={newUser.last_name}
							onIonChange={(e: any) =>
								setNewUser({
									...newUser,
									last_name: e.detail.value,
								})
							}></IonInput>
					</IonItem>
					<IonListHeader>Account</IonListHeader>
					<IonItem>
						<IonLabel position='stacked'>Account Name</IonLabel>
						<IonInput
							min='2'
							value={newUser.account_name}
							onIonChange={(e: any) =>
								setNewUser({
									...newUser,
									account_name: e.detail.value,
								})
							}></IonInput>
					</IonItem>
				</IonList>
				<IonButton
					class='ion-padding'
					expand='block'
					fill='outline'
					onClick={(e) => props.setNewUser(newUser)}>
					Create Account
				</IonButton>
			</IonContent>
		</>
	);
};
export default NewUser;
