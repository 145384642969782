/** @format */

import {
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardTitle,
	IonContent,
	IonPage,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { add } from "ionicons/icons";

const FrameEnvironment: React.FC = () => {
	const [showModal, setShowModal] = useState(false);
	const [newFrame, setNewFrame] = useState();
	useEffect(() => {
		setShowModal(false);
	}, [newFrame]);
	return (
		<IonCard>
			<IonCardContent>Frame Environment information</IonCardContent>
		</IonCard>
	);
};
export default FrameEnvironment;
