/** @format */

import { AmplifySignOut } from "@aws-amplify/ui-react";
/** @format */

import {
	IonBackButton,
	IonBadge,
	IonButton,
	IonButtons,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardTitle,
	IonContent,
	IonFab,
	IonFabButton,
	IonHeader,
	IonIcon,
	IonInput,
	IonItem,
	IonLabel,
	IonList,
	IonMenuButton,
	IonModal,
	IonPage,
	IonText,
	IonTitle,
	IonToolbar,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, useParams } from "react-router";
import ExploreContainer from "../../components/ExploreContainer";
import "../Page.css";
import MermaidContainer from "../../components/Mermaid";
import { add } from "ionicons/icons";
import { useDispatch, useSelector } from "react-redux";
import JSONPretty from "react-json-pretty";
import { getStudent, studentsReducer } from "../../redux/studentsSlice";
interface StudentProps
	extends RouteComponentProps<{
		id: string;
	}> {}
const Student: React.FC<StudentProps> = ({ match }) => {
	const [showModal, setShowModal] = useState(false);
	const [newFrame, setNewFrame] = useState();
	const dispatch = useDispatch();
	const studentsSlice = useSelector(studentsReducer);
	useEffect(() => {
		setShowModal(false);
	}, [newFrame]);
	useEffect(() => {
		if (match.params.id) {
			dispatch(getStudent(match.params.id));
		}
	}, [dispatch]);
	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot='start'>
						<IonBackButton defaultHref='/Students'></IonBackButton>
					</IonButtons>
					<IonButtons slot='end'>
						<IonMenuButton />
					</IonButtons>
					<IonTitle>
						{studentsSlice.getStudent.first_name}{" "}
						{studentsSlice.getStudent.last_name}
					</IonTitle>
				</IonToolbar>
			</IonHeader>

			<IonContent fullscreen>
				<IonHeader collapse='condense'>
					<IonToolbar>
						<IonTitle size='large'>
							{studentsSlice.getStudent.first_name}{" "}
							{studentsSlice.getStudent.last_name}
						</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonCard>
					<IonCardHeader>
						<IonCardTitle>{studentsSlice.getStudent.email}</IonCardTitle>
					</IonCardHeader>

					<IonCardContent>
						<IonList>
							<IonItem>
								<IonLabel>First Name</IonLabel>
								<IonText>{studentsSlice.getStudent.first_name}</IonText>
							</IonItem>
							<IonItem>
								<IonLabel>Last Name</IonLabel>
								<IonText>{studentsSlice.getStudent.last_name}</IonText>
							</IonItem>
							<IonItem>
								<IonLabel>Email</IonLabel>
								<IonText>{studentsSlice.getStudent.email}</IonText>
							</IonItem>
							<IonItem>
								<IonLabel>Phone Number</IonLabel>
								<IonText>{studentsSlice.getStudent.phone_number}</IonText>
							</IonItem>
						</IonList>
						{/* <JSONPretty data={studentsSlice.getStudent}></JSONPretty> */}
					</IonCardContent>
				</IonCard>
			</IonContent>
		</IonPage>
	);
};
export default Student;
