/** @format */

import {
	IonCardSubtitle,
	IonCardTitle,
	IonIcon,
	IonText,
	IonTitle,
} from "@ionic/react";
/** @format */

import { url } from "inspector";
import {
	caretForward,
	caretForwardOutline,
	chevronForward,
} from "ionicons/icons";
/** @format */

import React from "react";
import "./ExploreContainer.css";

interface ContainerProps {
	img: string;
	text: string;
}

const MainMenuIcon: React.FC<ContainerProps> = ({ img, text }) => {
	return (
		<div
			style={{
				height: 175,
				padding: 20,
				marginBottom: 10,
				display: "flex",
				alignItems: "center",
				justifyContent: "space-between",
				backgroundImage: `url(assets/img/${img})`,
				backgroundRepeat: "no-repeat",
				backgroundSize: "cover",
			}}>
			<IonCardTitle>{text}</IonCardTitle>
			<IonIcon icon={chevronForward} size='large'></IonIcon>
		</div>
	);
};

export default MainMenuIcon;
