/** @format */
import Amplify, { API, graphqlOperation, Hub, Auth } from "aws-amplify";
import awsconfig from "../aws-exports";
import gql from "graphql-tag";
import { from, fromEvent, pipe } from "rxjs";
import { pluck, first } from "rxjs/operators";
import { loader } from "graphql.macro";
import {
	ListCourses,
	NewCourse,
	SubscribeToCourseAccounts,
} from "./coursesGql.js";
import { v4 as uuidv4 } from "uuid";
// import { gqlListCourses } from "./courses.gql";
Amplify.configure(awsconfig);
//const query = loader("./courses.gql");
export const coursesMiddle = (store) => (next) => (action) => {
	//console.log("action", action);
	switch (action.type) {
		case "courses/initCourses":
			store.dispatch({
				type: "courses/getCourses",
				payload: "LOADING",
			});
			store.dispatch({
				type: "courses/setCourseSubscription",
				payload: "SUBSCRIBING",
			});
			break;
		case "courses/getCourses":
			from(
				API.graphql({
					query: ListCourses,
					variables: { accountID: store.getState().user.getAccount.id },
				})
			)
				.pipe(pluck("data", "getAccount", "courseAccounts", "items"))
				.subscribe({
					next: (data) => {
						console.log(data);
						store.dispatch({
							type: "courses/setCourses",
							payload: data,
						});
					},
					error: (error) => {
						console.log("Error getting course", error);
					},
				});

			break;
		case "courses/setCourseSubscription":
			console.log("Subscribe to courses", action.payload);

			switch (action.payload) {
				case "SUBSCRIBING":
					from(
						API.graphql({
							query: SubscribeToCourseAccounts,
							variables: { accountID: store.getState().user.getAccount.id },
						})
					).subscribe({
						next: (data) => {
							console.log("Data", data);
							store.dispatch({
								type: "courses/getCourses",
								payload: "LOADING",
							});
						},
						error: (error) => {
							console.log("Error subscribing to courses", error);
						},
					});

					break;
				default:
					break;
			}
			break;
		case "courses/createCourse":
			const variables = {
				...action.payload,
				id: uuidv4(),
				CourseAccountId: uuidv4(),
				userID: store.getState().user.getUser.id,
				accountID: store.getState().user.getAccount.id,
			};

			from(
				API.graphql({
					query: NewCourse,
					variables: variables,
				})
			).subscribe((res) => {
				// console.log("res", res);
			});

			break;
		default:
	}

	return next(action);
};
