/** @format */

import Menu from "./components/Menu";
import Page from "./pages/Page";
import React, { useEffect, useState } from "react";
import { IonApp, IonRouterOutlet, IonSplitPane } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route } from "react-router-dom";
import "./App.css";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

import { useDispatch, useSelector } from "react-redux";

import Amplify, { Auth } from "aws-amplify";
import awsconfig from "./aws-exports";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";
import {
	userReducer,
	initUser,
	setAuthData,
	setAuthState,
	setCreateUser,
} from "./redux/userSlice";
import { appReducer, initApp, setDismissError } from "./redux/appSlice";
import { AmplifyAuthenticator } from "@aws-amplify/ui-react";
import Courses from "./pages/Courses/Courses";
import Course from "./pages/Courses/Course";
import Frame from "./pages/Courses/Frame";
import Students from "./pages/Students/Students";
import Student from "./pages/Students/Student";
import Registrations from "./pages/Registrations/Registrations";
import Registration from "./pages/Registrations/Registration";
import Login from "./pages/Login";
import Home from "./pages/public/Home";
import NewUser from "./components/NewUser";
import Error from "./components/Error";
import CourseNew from "./pages/Courses/CourseNew";
import { attachEventProps } from "@aws-amplify/ui-react/lib-esm/react-component-lib/utils";

Amplify.configure(awsconfig);

const App: React.FC = () => {
	const dispatch = useDispatch();
	const appSlice = useSelector(appReducer);
	const userSlice = useSelector(userReducer);
	const [authed, setAuthed] = useState(false);

	useEffect(() => {
		dispatch(initApp("init"));
		dispatch(initUser("init"));
		/*
		return onAuthUIStateChange((nextAuthState) => {
			console.log("nextAuthState", nextAuthState);
			dispatch(setAuthState(nextAuthState));
		});*/
	}, [dispatch]);

	useEffect(() => {
		switch (appSlice.hub.UIAuth.message) {
			case "signedin":
				switch (userSlice.getUser.status) {
					case "ACTIVE":
						if (userSlice.getAccount.status === "ACTIVE") {
							setAuthed(true);
						}
						break;
					default:
						setAuthed(false);
				}

				break;
			default:
				setAuthed(false);
				break;
		}
	}, [
		dispatch,
		appSlice.hub.UIAuth.message,
		userSlice.getUser.status,
		userSlice.getAccount.status,
	]);
	return (
		<IonApp>
			<IonReactRouter>
				<IonSplitPane contentId='main' when='md'>
					<Menu />

					{/* 
					{userSlice.getUser.status ? (
						<IonModal
							animated={false}
							isOpen={userSlice.getUser.status === "ACTIVE" ? false : true}>
							<NewUser setNewUser={setNewUser}></NewUser>
						</IonModal>
					) : null}*/}

					<IonRouterOutlet id='main'>
						<Route path='/login' component={Login} exact />
						<Route path='/home' component={Home} exact />
						<Route
							path='/app'
							render={() => {
								return authed ? <Page /> : <Login />;
							}}
							exact
						/>
						<Route
							path='/app/courses'
							render={() => {
								return authed ? <Courses /> : <Login />;
							}}
							exact
						/>
						<Route
							path='/app/courses/new'
							render={() => {
								return authed ? <CourseNew /> : <Login />;
							}}
							exact
						/>
						<Route
							path='/app/courses/course/:id'
							render={(props) => {
								return authed ? <Course {...props} /> : <Login />;
							}}
							exact
						/>
						<Route
							path='/app/courses/course/frame/:frameid'
							render={(props) => {
								return authed ? <Frame {...props} /> : <Login />;
							}}
							exact
						/>
						<Route
							path='/app/students'
							render={() => {
								return authed ? <Students /> : <Login />;
							}}
							exact
						/>
						<Route
							path='/app/students/student/:id'
							render={(props) => {
								return authed ? <Student {...props} /> : <Login />;
							}}
							exact
						/>

						<Route
							path='/app/registrations'
							render={() => {
								return authed ? <Registration /> : <Login />;
							}}
							exact
						/>
						<Route
							path='/app/registrations/registration'
							render={() => {
								return authed ? <Registration /> : <Login />;
							}}
							exact
						/>
						<Redirect from='/' to='/app' exact />
					</IonRouterOutlet>
				</IonSplitPane>
			</IonReactRouter>

			{/* 
					<IonModal isOpen={appSlice.error.show}>
						<Error
							title={appSlice.error.title}
							message={appSlice.error.message}
							onDismissError={(e: any) => dispatch(setDismissError())}></Error>
					</IonModal>*/}
		</IonApp>
	);
};

export default App;
