/** @format */

import gql from "graphql-tag";
export const GetFrameQuery = gql`
	query GetFrameQuery($id: ID!) {
		getFrame(id: $id) {
			version
			updatedAt
			type
			status
			name
			id
			icon
			createdAt
			courseID
			CourseFrameId
			question_graded
			question_question
			question_answer1
			question_answer1_correct
			question_answer2
			question_answer2_correct
			question_answer3
			question_answer3_correct
			question_answer4
			question_answer4_correct
			question_answer5
			question_answer5_correct
			question_remediation
			imagetext_text
			imagetext_speech
			imagetext_image
		}
	}
`;
export const onFrameByFrameId = gql`
	subscription MySubscription($id: ID = "") {
		onFrameByFrameId(id: $id) {
			id
		}
	}
`;

export const UpdateFrame = gql`
	mutation UpdateFrameMutation(
		$CourseFrameId: ID!
		$courseID: ID!
		$expectedVersion: Int = 10
		$icon: String = ""
		$id: ID!
		$name: String = ""
		$question_answer1: String = ""
		$question_answer1_correct: Boolean = false
		$question_answer2: String = ""
		$question_answer2_correct: Boolean = false
		$question_answer3: String = ""
		$question_answer3_correct: Boolean = false
		$question_answer4: String = ""
		$question_answer4_correct: Boolean = false
		$question_answer5: String = ""
		$question_answer5_correct: Boolean = false
		$question_graded: Boolean = false
		$question_question: String = ""
		$question_remediation: String = ""
		$imagetext_text: String = ""
		$imagetext_speech: String = ""
		$imagetext_image: String = ""
		$type: FRAMETYPE = IMAGETEXT
		$status: STATUS = ACTIVE
	) {
		updateFrame(
			input: {
				CourseFrameId: $CourseFrameId
				courseID: $courseID
				expectedVersion: $expectedVersion
				icon: $icon
				name: $name
				question_answer1: $question_answer1
				question_answer1_correct: $question_answer1_correct
				question_answer2: $question_answer2
				question_answer2_correct: $question_answer2_correct
				question_answer3: $question_answer3
				question_answer3_correct: $question_answer3_correct
				question_answer4: $question_answer4
				question_answer4_correct: $question_answer4_correct
				question_answer5: $question_answer5
				question_answer5_correct: $question_answer5_correct
				question_graded: $question_graded
				question_question: $question_question
				question_remediation: $question_remediation
				imagetext_text: $imagetext_text
				imagetext_speech: $imagetext_speech
				imagetext_image: $imagetext_image
				status: $status
				type: $type
				id: $id
			}
		) {
			id
		}
	}
`;
