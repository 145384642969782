/** @format */

import { AmplifySignOut } from "@aws-amplify/ui-react";
/** @format */

import {
	IonBackButton,
	IonBadge,
	IonButton,
	IonButtons,
	IonContent,
	IonFab,
	IonFabButton,
	IonHeader,
	IonIcon,
	IonInput,
	IonItem,
	IonLabel,
	IonList,
	IonMenuButton,
	IonModal,
	IonPage,
	IonTitle,
	IonToolbar,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import ExploreContainer from "../../components/ExploreContainer";
import "../Page.css";
import MermaidContainer from "../../components/Mermaid";
import { add, book, bookOutline } from "ionicons/icons";
import { useDispatch, useSelector } from "react-redux";
import { studentsReducer } from "../../redux/studentsSlice";
import { coursesReducer, initCourses } from "../../redux/coursesSlice";

const Courses: React.FC = () => {
	const [showModal, setShowModal] = useState(false);
	const [newCourse, setNewCourse] = useState();
	const dispatch = useDispatch();
	const coursesSlice = useSelector(coursesReducer);
	useEffect(() => {
		setShowModal(false);
	}, [newCourse]);
	useEffect(() => {
		dispatch(initCourses(true));
		console.log("courses", coursesSlice.courses.items);
	}, [dispatch]);
	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot='start'>
						<IonBackButton defaultHref='/app'></IonBackButton>
					</IonButtons>
					<IonButtons slot='end'>
						<IonMenuButton />
					</IonButtons>
					<IonTitle>Courses</IonTitle>
				</IonToolbar>
			</IonHeader>

			<IonContent fullscreen>
				<IonHeader collapse='condense'>
					<IonToolbar>
						<IonTitle size='large'>Courses</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonList>
					{coursesSlice.courses.items.map((course: any) => (
						<IonItem
							routerLink={"/app/courses/course/" + course.courses.items[0].id}
							key={course.courses.items[0].id}>
							<IonIcon icon={bookOutline} slot='start'></IonIcon>
							<IonLabel>{course.courses.items[0].course_name}</IonLabel>
						</IonItem>
					))}
				</IonList>
				<IonFab horizontal='end' vertical='bottom' slot='fixed'>
					<IonFabButton routerLink='/app/courses/new'>
						<IonIcon icon={add}></IonIcon>
					</IonFabButton>
					{/* <IonFabButton onClick={(e) => setShowModal(true)}>
						<IonIcon icon={add}></IonIcon>
					</IonFabButton> */}
				</IonFab>
			</IonContent>
		</IonPage>
	);
};
export default Courses;
