/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:1cd8d5af-9f0b-4379-a9e0-83ebd6f5f83a",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_GnIqI6nu6",
    "aws_user_pools_web_client_id": "1rqeu9umt126mcqhcv2uptq5j8",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://l5auslcjuzhzdnkpzgm4d3ufnm.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-dwtph7yz2jhadlicpyq25sgcnq",
    "aws_mobile_analytics_app_id": "326419d49cdb43269af3f55bfc2bd9df",
    "aws_mobile_analytics_app_region": "us-west-2",
    "aws_user_files_s3_bucket": "310viarspace52038-dev",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
