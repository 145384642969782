/** @format */
import Amplify, { API, graphqlOperation, Hub, Auth } from "aws-amplify";
import awsconfig from "../aws-exports";
import gql from "graphql-tag";
import { from, fromEvent, pipe } from "rxjs";
import { pluck, first } from "rxjs/operators";
import * as Bowser from "bowser";
import { I18n } from "aws-amplify";
import { Translations } from "@aws-amplify/ui-components";
import {
	NewFrame,
	ListFrames,
	UpdateFrame,
	onFrameByFrameId,
} from "./frameGql";
import { v4 as uuidv4 } from "uuid";
import { GetFrameQuery } from "./frameGql";
Amplify.configure(awsconfig);
export const frameMiddle = (store) => (next) => (action) => {
	switch (action.type) {
		case "frame/initFrame":
			store.dispatch({ type: "frame/getFrame", payload: action.payload });
			store.dispatch({
				type: "frame/subscribeToFrame",
				payload: action.payload,
			});
			break;
		case "frame/getFrame":
			//console.log("About to load", action.payload);
			from(
				API.graphql({
					query: GetFrameQuery,
					variables: { id: action.payload },
				})
			)
				.pipe(pluck("data", "getFrame"))
				.subscribe((next) => {
					store.dispatch({
						type: "frame/setFrame",
						payload: next,
					});
					//console.log("next", next);
				});
			break;
		case "frame/subscribeToFrame":
			from(
				API.graphql({
					query: onFrameByFrameId,
					variables: { id: action.payload },
				})
			).subscribe((next) => {
				store.dispatch({
					type: "frame/getFrame",
					payload: action.payload,
				});
			});
			break;
		case "frame/updateFrame":
			let variables = { ...action.payload };
			variables.expectedVersion = store.getState().frame.getFrame.version;

			from(
				API.graphql({
					query: UpdateFrame,
					variables: variables,
				})
			).subscribe(
				(next) => {
					//console.log("Update feame successfully", next);
				},
				(error) => {
					console.log("Error updating frame", error);
				}
			);
			break;
		default:
	}

	return next(action);
};
