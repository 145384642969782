/** @format */

import { AmplifySignOut } from "@aws-amplify/ui-react";
/** @format */

import {
	IonBackButton,
	IonBadge,
	IonButton,
	IonButtons,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardTitle,
	IonContent,
	IonFab,
	IonFabButton,
	IonHeader,
	IonIcon,
	IonInput,
	IonItem,
	IonLabel,
	IonList,
	IonMenuButton,
	IonModal,
	IonPage,
	IonTitle,
	IonToolbar,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import ExploreContainer from "../../components/ExploreContainer";
import "../Page.css";
import MermaidContainer from "../../components/Mermaid";
import { add } from "ionicons/icons";

const Registration: React.FC = () => {
	const [showModal, setShowModal] = useState(false);
	const [newFrame, setNewFrame] = useState();
	useEffect(() => {
		setShowModal(false);
	}, [newFrame]);
	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot='start'>
						<IonBackButton defaultHref='/Registrations'></IonBackButton>
					</IonButtons>
					<IonButtons slot='end'>
						<IonMenuButton />
					</IonButtons>
					<IonTitle>Registration</IonTitle>
				</IonToolbar>
			</IonHeader>

			<IonContent fullscreen>
				<IonHeader collapse='condense'>
					<IonToolbar>
						<IonTitle size='large'>Registration</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonCard>
					<IonCardHeader>
						<IonCardTitle>Registration Detail</IonCardTitle>
					</IonCardHeader>

					<IonCardContent>Registration information</IonCardContent>
				</IonCard>
			</IonContent>
		</IonPage>
	);
};
export default Registration;
